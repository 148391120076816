import React, { useCallback } from "react"
import {
  Box,
  Button,
  FormContainer,
  ButtonDiv,
  TitleContainer,
  Container,
  RowDiv
} from "./style"
import Input from "./Input"
import InputArea from "./InputArea"
import { Formik } from "formik"
import * as Yup from "yup"
import { navigate } from "gatsby"

const isClient = typeof window !== "undefined"

const EmailContainer = () => {
  // 
  const onSubmit = useCallback(values => {
    console.log()

      const consult = `
      Quiero ser socio, mis datos son: 
      Nombre: ${values.name},
      Domiciliado en: ${values.location},
        Número de Telefono: ${values.phoneNumber},
        Percibiendo haberes en: ${values.work},
        Número de presupuesto: ${values.numberPres},
        Con el cargo de: ${values.position},
        Destinado en: ${values.positionLocation},
        Cedula: ${values.ci},
      `
      const formElement = { "name": values.name, "email": values.email, "subject": "Nuevo Socio", "consult": consult };
      const data = new URLSearchParams();
for (const pair of new FormData(formElement)) {
    data.append(pair[0], pair[1]);
}
      fetch("https://formspree.io/f/xqknkvgv", {
        method: "POST",
        body: data
      })
        
  }, [])
  return (
    <Container>
      <TitleContainer id="featured">
        <h2>Quiero ser Socio</h2>
        <p>Dejanos tus datos para que podamos asociarte</p>
      </TitleContainer>
      <Box>
        <Formik
          initialValues={{
            name: "",
            location: "",
            phoneNumber: "",
            work: "",
            numberPres: "",
            position: "",
            positionLocation: "",
            ci: "",
            email: "",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(),
          })}
          onSubmit={onSubmit}
        >
          {({ handleChange, handleSubmit, errors, isSubmitting, values }) => {
            return (
              <form action="https://formspree.io/f/xqknkvgv"
              method="POST">
                <RowDiv>

                <Input
                
                  placeholder="Nombre"
                  name="name"
                  field={values.name}
                  setField={handleChange}
                />
                <Input
                  placeholder="Domicilio"
                  name="location"
                  field={values.location}
                  setField={handleChange}
                />
                </RowDiv>
                <RowDiv>
                <Input
                  placeholder="Telefonó"
                  name="phoneNumber"
                  field={values.phoneNumber}
                  setField={handleChange}
                />
                <Input
                  placeholder="Percibiendo haberes en"
                  name="work"
                  field={values.work}
                  setField={handleChange}
                  />
                  </RowDiv>
                  <RowDiv>
                <Input
                  placeholder="Numero de Presupuesto"
                  name="numberPres"
                  field={values.numberPres}
                  setField={handleChange}
                />
                
                   </RowDiv>
                   <RowDiv>
                <Input
                  placeholder="Cargo"
                  name="position"
                  field={values.position}
                  setField={handleChange}
                />
                <Input
                  placeholder="Donde esta destinado"
                  name="positionLocation"
                  field={values.positionLocation}
                  setField={handleChange}
                />
                 </RowDiv>
                 <RowDiv>
                <Input
                  placeholder="Cédula"
                  name="ci"
                  field={values.ci}
                  setField={handleChange}
                />
                <Input
                  placeholder="Email"
                  name="email"
                  field={values.email}
                  setField={handleChange}
                />
                  </RowDiv>

                  <textarea style={{display: "none"}} name="message" value={`
                  Quiero ser socio, mis datos son: 
                  Nombre: ${values.name},
                  Domiciliado en: ${values.location},
                    Número de Telefono: ${values.phoneNumber},
                    Percibiendo haberes en: ${values.work},
                    Número de presupuesto: ${values.numberPres},
                    Con el cargo de: ${values.position},
                    Destinado en: ${values.positionLocation},
                    Cedula: ${values.ci},
                  `}></textarea>
                  <input style={{display: "none"}} type="text" name="subject" value="Quiero ser socio" />
                <ButtonDiv>
                  {isClient ? (
                    <Button type="submit">Enviar Consulta</Button>
                  ) : (
                    <></>
                  )}
                </ButtonDiv>
              </form>
            )
          }}
        </Formik>
      </Box>
    </Container>
  )
}

export default EmailContainer
